.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.espacamento {
  grid-gap: 10px 10px;
  padding-left: 22rem;
}

.img {
  width: 50;
  height: 50;
}

.negrito {
  font-weight: bolder;
 }

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.background-navbar {
  background: -webkit-linear-gradient(right, #76b852, #6f72c2);
  background: -moz-linear-gradient(right, #76b852, #6f72c2);
  background: -o-linear-gradient(right, #76b852, #6f72c2);
  background: linear-gradient(to left, #76b852, #6f72c2);
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.form {
  position: relative;
  z-index: 1;
  background: #FFFFFF;
  max-width: 360px;
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.login-page {
  width: 360px;
  padding: 13% 0 0;
  margin: auto;
}

body {
  background: #76b852; /* fallback for old browsers */
  background: -webkit-linear-gradient(right, #76b852, #6f72c2);
  background: -moz-linear-gradient(right, #76b852, #6f72c2);
  background: -o-linear-gradient(right, #76b852, #6f72c2);
  background: linear-gradient(to left, #76b852, #6f72c2);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;      
}

.font-robot {
  font-family: "Roboto", sans-serif;
}

.monospace {
  font-family: "Lucida Console", Courier, monospace;
}